export const STRIPE_KEY_PROD = "pk_live_51KPqzCHZAZKDaZHnzHWIOJ3qtGeZYToIvfWVOg2Ad7tLHNIAvYpfjPM9PRNZJHJcstb4xKtZQEyteoR4cf9j612X00wgSUA9iY";
export const STRIPE_KEY_DEV = "pk_test_51KPqzCHZAZKDaZHnThAenGfuW1EdZ1kO6BVRVhXBY56H72csS3E1bzTP6SEb82mgdpgRAQXnDbK5OJF68DHYHTZQ006HAEkfDv";
export const TO_INCH = 0.393700787;
export const TO_POUND = 2.2046226218;
export const TO_FEET = 35.315;
export const HS_CODE_VALIDATION_MESSAGE = <p className="mb-0">Please use the correct HS Code format which is 0000.00.00.00</p>;
export const HS_CODE_REGEX = /^\d{4}\.\d{2}.\d{2}?(\.\d{2})?$/;
export const UPS_FEDEX_TRACKING_ID_REGEX = /^(1Z[A-Z0-9]{16}|\d{11}|T\d{10}|.{12})$/;
export const UK_TRACKING_ID_REGEX = /^[a-zA-Z0-9]{11,15}$/;

export const EMAIL_REGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
export const INVALIDATE_SPACES_REGEX = /^(?!\s*$).+/;
export const EMPTY_STRING_PREVENT_ONLY_SPACES_REGEX = /^$|^(?=\s*\S).*$/;
export const FIFE_DIGITS_REGEX = /^[+-]?\d*\.?\d{0,5}$/;
export const UUID_REGEX = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;

export const LAZY_COUNT = 20;
export const LAZY_MORE_COUNT = 10;
export const LAZY_TRACKING_COUNT = 4;
export const LAZY_TRACKING_MORE_COUNT = 3;

export const MAX_REQUEST_SIZE = 19.5e6;

export const DIMENSION_VALUES = {
    kg: false,
    cm: false,
    inch: true,
    pound: true,
};

export const ADMIN_TABS_DEFAULT_ORDERING = "-created_at";

export const searchForElement = (columns) => {
    let input = document.getElementById("myInput");
    let filter = input.value.toUpperCase();
    let table = document.getElementById("Test");
    let trAll = table.getElementsByTagName("tr");
    if (filter !== "" && filter !== " ") {
        for (let i = 1; i < trAll.length; i++) {
            let isMatch = false;
            let tdAll = trAll[i].getElementsByTagName("td");
            for (let j = 0; j < columns.length && !isMatch; j++) {
                let column = tdAll[columns[j]];
                if (column) {
                    let txtValue = column.textContent || column.innerText;
                    isMatch = txtValue.toUpperCase().indexOf(filter) > -1 ? true : false;
                }
            }

            trAll[i].style.display = isMatch ? "" : "none";
        }
    } else {
        for (let i = 1; i < trAll.length; i++) {
            trAll[i].style.display = "";
        }
    }
};

const extractAndSumCellNumbers = (htmlString) => {
    const numbers = htmlString.match(/[\d.]+/g);

    if (numbers) {
        return numbers.reduce((acc, num) => acc + parseFloat(num), 0);
    }

    return 0;
};

export const sortTable = (e) => {
    if (typeof e.target.cellIndex !== "number") {
        return;
    }

    let th = e.target;
    let colNum = e.target.cellIndex;
    let type = e.target.dataset.type;
    let tbody = document.getElementById("tBody");
    let rowsArray = Array.from(tbody.rows);

    // compare(a, b) compares two rows, need for sorting
    let compare;
    // console.log(th.classList.value);
    switch (type) {
        case "number":
            if (th.classList.value.includes("down")) {
                compare = function (rowA, rowB) {
                    const numA = extractAndSumCellNumbers(rowA.cells[colNum].innerHTML);
                    const numB = extractAndSumCellNumbers(rowB.cells[colNum].innerHTML);
                    return numA - numB;
                };
                th.classList.value = th.classList.value.replace("down", "up");
            } else {
                compare = function (rowA, rowB) {
                    const numA = extractAndSumCellNumbers(rowA.cells[colNum].innerHTML);
                    const numB = extractAndSumCellNumbers(rowB.cells[colNum].innerHTML);
                    return numB - numA;
                };
                th.classList.value = th.classList.value.replace("up", "down");
            }
            break;
        case "string":
            if (th.classList.value.includes("down")) {
                compare = function (rowA, rowB) {
                    return rowA.cells[colNum].innerHTML > rowB.cells[colNum].innerHTML ? 1 : -1;
                };
                th.classList.value = th.classList.value.replace("down", "up");
            } else {
                compare = function (rowA, rowB) {
                    return rowB.cells[colNum].innerHTML > rowA.cells[colNum].innerHTML ? 1 : -1;
                };
                th.classList.value = th.classList.value.replace("up", "down");
            }
            break;
        default:
            break;
    }

    // sort
    rowsArray.sort(compare);

    tbody.append(...rowsArray);
};

export const sendEmail = (email) => {
    let link = `mailto:${email}`;

    window.location.href = link;
};

export const getMinMedMaxValue = (productDimantions) => {
    let max;
    let min;
    let medium;
    min = Math.min(...productDimantions);
    max = Math.max(...productDimantions);
    let indexMin = productDimantions.indexOf(min);
    if (indexMin > -1) {
        productDimantions.splice(indexMin, 1);
    }
    let indexMax = productDimantions.indexOf(max);
    if (indexMax > -1) {
        productDimantions.splice(indexMax, 1);
    }
    medium = parseFloat(productDimantions[0]);
    return { min, medium, max };
};

export const createNestedObject = (obj, path, value) => {
    let schema = obj;
    let pathList = path.split(".");
    let length = pathList.length;
    for (let i = 0; i < length - 1; i++) {
        let elem = pathList[i];
        if (!schema[elem]) schema[elem] = {};
        schema = schema[elem];
    }

    schema[pathList[length - 1]] = value;
};

export const COUNTRIES = [
    {
        name: "Andorra",
        code: "AD",
    },
    {
        name: "United Arab Emirates",
        code: "AE",
    },
    {
        name: "Afghanistan",
        code: "AF",
    },
    {
        name: "Antigua and Barbuda",
        code: "AG",
    },
    {
        name: "Anguilla",
        code: "AI",
    },
    {
        name: "Albania",
        code: "AL",
    },
    {
        name: "Armenia",
        code: "AM",
    },
    {
        name: "Angola",
        code: "AO",
    },
    {
        name: "Antarctica",
        code: "AQ",
    },
    {
        name: "Argentina",
        code: "AR",
    },
    {
        name: "American Samoa",
        code: "AS",
    },
    {
        name: "Austria",
        code: "AT",
    },
    {
        name: "Australia",
        code: "AU",
    },
    {
        name: "Aruba",
        code: "AW",
    },
    {
        name: "Åland Islands",
        code: "AX",
    },
    {
        name: "Azerbaijan",
        code: "AZ",
    },
    {
        name: "Bosnia and Herzegovina",
        code: "BA",
    },
    {
        name: "Barbados",
        code: "BB",
    },
    {
        name: "Bangladesh",
        code: "BD",
    },
    {
        name: "Belgium",
        code: "BE",
    },
    {
        name: "Burkina Faso",
        code: "BF",
    },
    {
        name: "Bulgaria",
        code: "BG",
    },
    {
        name: "Bahrain",
        code: "BH",
    },
    {
        name: "Burundi",
        code: "BI",
    },
    {
        name: "Benin",
        code: "BJ",
    },
    {
        name: "Saint Barthélemy",
        code: "BL",
    },
    {
        name: "Bermuda",
        code: "BM",
    },
    {
        name: "Brunei",
        code: "BN",
    },
    {
        name: "Bolivia",
        code: "BO",
    },
    {
        name: "Bonaire, Sint Eustatius and Saba",
        code: "BQ",
    },
    {
        name: "Brazil",
        code: "BR",
    },
    {
        name: "Bahamas",
        code: "BS",
    },
    {
        name: "Bhutan",
        code: "BT",
    },
    {
        name: "Bouvet Island",
        code: "BV",
    },
    {
        name: "Botswana",
        code: "BW",
    },
    {
        name: "Belarus",
        code: "BY",
    },
    {
        name: "Belize",
        code: "BZ",
    },
    {
        name: "Canada",
        code: "CA",
    },
    {
        name: "Cocos (Keeling) Islands",
        code: "CC",
    },
    {
        name: "Congo (the Democratic Republic of the)",
        code: "CD",
    },
    {
        name: "Central African Republic",
        code: "CF",
    },
    {
        name: "Congo",
        code: "CG",
    },
    {
        name: "Switzerland",
        code: "CH",
    },
    {
        name: "Côte d'Ivoire",
        code: "CI",
    },
    {
        name: "Cook Islands",
        code: "CK",
    },
    {
        name: "Chile",
        code: "CL",
    },
    {
        name: "Cameroon",
        code: "CM",
    },
    {
        name: "China",
        code: "CN",
    },
    {
        name: "Colombia",
        code: "CO",
    },
    {
        name: "Costa Rica",
        code: "CR",
    },
    {
        name: "Cuba",
        code: "CU",
    },
    {
        name: "Cabo Verde",
        code: "CV",
    },
    {
        name: "Curaçao",
        code: "CW",
    },
    {
        name: "Christmas Island",
        code: "CX",
    },
    {
        name: "Cyprus",
        code: "CY",
    },
    {
        name: "Czechia",
        code: "CZ",
    },
    {
        name: "Germany",
        code: "DE",
    },
    {
        name: "Djibouti",
        code: "DJ",
    },
    {
        name: "Denmark",
        code: "DK",
    },
    {
        name: "Dominica",
        code: "DM",
    },
    {
        name: "Dominican Republic",
        code: "DO",
    },
    {
        name: "Algeria",
        code: "DZ",
    },
    {
        name: "Ecuador",
        code: "EC",
    },
    {
        name: "Estonia",
        code: "EE",
    },
    {
        name: "Egypt",
        code: "EG",
    },
    {
        name: "Western Sahara",
        code: "EH",
    },
    {
        name: "Eritrea",
        code: "ER",
    },
    {
        name: "Spain",
        code: "ES",
    },
    {
        name: "Ethiopia",
        code: "ET",
    },
    {
        name: "Finland",
        code: "FI",
    },
    {
        name: "Fiji",
        code: "FJ",
    },
    {
        name: "Falkland Islands (Malvinas)",
        code: "FK",
    },
    {
        name: "Micronesia (Federated States of)",
        code: "FM",
    },
    {
        name: "Faroe Islands",
        code: "FO",
    },
    {
        name: "France",
        code: "FR",
    },
    {
        name: "Gabon",
        code: "GA",
    },
    {
        name: "United Kingdom",
        code: "GB",
    },
    {
        name: "Grenada",
        code: "GD",
    },
    {
        name: "Georgia",
        code: "GE",
    },
    {
        name: "French Guiana",
        code: "GF",
    },
    {
        name: "Guernsey",
        code: "GG",
    },
    {
        name: "Ghana",
        code: "GH",
    },
    {
        name: "Gibraltar",
        code: "GI",
    },
    {
        name: "Greenland",
        code: "GL",
    },
    {
        name: "Gambia",
        code: "GM",
    },
    {
        name: "Guinea",
        code: "GN",
    },
    {
        name: "Guadeloupe",
        code: "GP",
    },
    {
        name: "Equatorial Guinea",
        code: "GQ",
    },
    {
        name: "Greece",
        code: "GR",
    },
    {
        name: "South Georgia and the South Sandwich Islands",
        code: "GS",
    },
    {
        name: "Guatemala",
        code: "GT",
    },
    {
        name: "Guam",
        code: "GU",
    },
    {
        name: "Guinea-Bissau",
        code: "GW",
    },
    {
        name: "Guyana",
        code: "GY",
    },
    {
        name: "Hong Kong",
        code: "HK",
    },
    {
        name: "Heard Island and McDonald Islands",
        code: "HM",
    },
    {
        name: "Honduras",
        code: "HN",
    },
    {
        name: "Croatia",
        code: "HR",
    },
    {
        name: "Haiti",
        code: "HT",
    },
    {
        name: "Hungary",
        code: "HU",
    },
    {
        name: "Indonesia",
        code: "ID",
    },
    {
        name: "Ireland",
        code: "IE",
    },
    {
        name: "Israel",
        code: "IL",
    },
    {
        name: "Isle of Man",
        code: "IM",
    },
    {
        name: "India",
        code: "IN",
    },
    {
        name: "British Indian Ocean Territory",
        code: "IO",
    },
    {
        name: "Iraq",
        code: "IQ",
    },
    {
        name: "Iran",
        code: "IR",
    },
    {
        name: "Iceland",
        code: "IS",
    },
    {
        name: "Italy",
        code: "IT",
    },
    {
        name: "Jersey",
        code: "JE",
    },
    {
        name: "Jamaica",
        code: "JM",
    },
    {
        name: "Jordan",
        code: "JO",
    },
    {
        name: "Japan",
        code: "JP",
    },
    {
        name: "Kenya",
        code: "KE",
    },
    {
        name: "Kyrgyzstan",
        code: "KG",
    },
    {
        name: "Cambodia",
        code: "KH",
    },
    {
        name: "Kiribati",
        code: "KI",
    },
    {
        name: "Comoros",
        code: "KM",
    },
    {
        name: "Saint Kitts and Nevis",
        code: "KN",
    },
    {
        name: "North Korea",
        code: "KP",
    },
    {
        name: "South Korea",
        code: "KR",
    },
    {
        name: "Kuwait",
        code: "KW",
    },
    {
        name: "Cayman Islands",
        code: "KY",
    },
    {
        name: "Kazakhstan",
        code: "KZ",
    },
    {
        name: "Laos",
        code: "LA",
    },
    {
        name: "Lebanon",
        code: "LB",
    },
    {
        name: "Saint Lucia",
        code: "LC",
    },
    {
        name: "Liechtenstein",
        code: "LI",
    },
    {
        name: "Sri Lanka",
        code: "LK",
    },
    {
        name: "Liberia",
        code: "LR",
    },
    {
        name: "Lesotho",
        code: "LS",
    },
    {
        name: "Lithuania",
        code: "LT",
    },
    {
        name: "Luxembourg",
        code: "LU",
    },
    {
        name: "Latvia",
        code: "LV",
    },
    {
        name: "Libya",
        code: "LY",
    },
    {
        name: "Morocco",
        code: "MA",
    },
    {
        name: "Monaco",
        code: "MC",
    },
    {
        name: "Moldova",
        code: "MD",
    },
    {
        name: "Montenegro",
        code: "ME",
    },
    {
        name: "Saint Martin (French part)",
        code: "MF",
    },
    {
        name: "Madagascar",
        code: "MG",
    },
    {
        name: "Marshall Islands",
        code: "MH",
    },
    {
        name: "North Macedonia",
        code: "MK",
    },
    {
        name: "Mali",
        code: "ML",
    },
    {
        name: "Myanmar",
        code: "MM",
    },
    {
        name: "Mongolia",
        code: "MN",
    },
    {
        name: "Macao",
        code: "MO",
    },
    {
        name: "Northern Mariana Islands",
        code: "MP",
    },
    {
        name: "Martinique",
        code: "MQ",
    },
    {
        name: "Mauritania",
        code: "MR",
    },
    {
        name: "Montserrat",
        code: "MS",
    },
    {
        name: "Malta",
        code: "MT",
    },
    {
        name: "Mauritius",
        code: "MU",
    },
    {
        name: "Maldives",
        code: "MV",
    },
    {
        name: "Malawi",
        code: "MW",
    },
    {
        name: "Mexico",
        code: "MX",
    },
    {
        name: "Malaysia",
        code: "MY",
    },
    {
        name: "Mozambique",
        code: "MZ",
    },
    {
        name: "Namibia",
        code: "NA",
    },
    {
        name: "New Caledonia",
        code: "NC",
    },
    {
        name: "Niger",
        code: "NE",
    },
    {
        name: "Norfolk Island",
        code: "NF",
    },
    {
        name: "Nigeria",
        code: "NG",
    },
    {
        name: "Nicaragua",
        code: "NI",
    },
    {
        name: "Netherlands",
        code: "NL",
    },
    {
        name: "Norway",
        code: "NO",
    },
    {
        name: "Nepal",
        code: "NP",
    },
    {
        name: "Nauru",
        code: "NR",
    },
    {
        name: "Niue",
        code: "NU",
    },
    {
        name: "New Zealand",
        code: "NZ",
    },
    {
        name: "Oman",
        code: "OM",
    },
    {
        name: "Panama",
        code: "PA",
    },
    {
        name: "Peru",
        code: "PE",
    },
    {
        name: "French Polynesia",
        code: "PF",
    },
    {
        name: "Papua New Guinea",
        code: "PG",
    },
    {
        name: "Philippines",
        code: "PH",
    },
    {
        name: "Pakistan",
        code: "PK",
    },
    {
        name: "Poland",
        code: "PL",
    },
    {
        name: "Saint Pierre and Miquelon",
        code: "PM",
    },
    {
        name: "Pitcairn",
        code: "PN",
    },
    {
        name: "Puerto Rico",
        code: "PR",
    },
    {
        name: "Palestine, State of",
        code: "PS",
    },
    {
        name: "Portugal",
        code: "PT",
    },
    {
        name: "Palau",
        code: "PW",
    },
    {
        name: "Paraguay",
        code: "PY",
    },
    {
        name: "Qatar",
        code: "QA",
    },
    {
        name: "Réunion",
        code: "RE",
    },
    {
        name: "Romania",
        code: "RO",
    },
    {
        name: "Serbia",
        code: "RS",
    },
    {
        name: "Rwanda",
        code: "RW",
    },
    {
        name: "Saudi Arabia",
        code: "SA",
    },
    {
        name: "Solomon Islands",
        code: "SB",
    },
    {
        name: "Seychelles",
        code: "SC",
    },
    {
        name: "Sudan",
        code: "SD",
    },
    {
        name: "Sweden",
        code: "SE",
    },
    {
        name: "Singapore",
        code: "SG",
    },
    {
        name: "Saint Helena, Ascension and Tristan da Cunha",
        code: "SH",
    },
    {
        name: "Slovenia",
        code: "SI",
    },
    {
        name: "Svalbard and Jan Mayen",
        code: "SJ",
    },
    {
        name: "Slovakia",
        code: "SK",
    },
    {
        name: "Sierra Leone",
        code: "SL",
    },
    {
        name: "San Marino",
        code: "SM",
    },
    {
        name: "Senegal",
        code: "SN",
    },
    {
        name: "Somalia",
        code: "SO",
    },
    {
        name: "Suriname",
        code: "SR",
    },
    {
        name: "South Sudan",
        code: "SS",
    },
    {
        name: "Sao Tome and Principe",
        code: "ST",
    },
    {
        name: "El Salvador",
        code: "SV",
    },
    {
        name: "Sint Maarten (Dutch part)",
        code: "SX",
    },
    {
        name: "Syria",
        code: "SY",
    },
    {
        name: "Eswatini",
        code: "SZ",
    },
    {
        name: "Turks and Caicos Islands",
        code: "TC",
    },
    {
        name: "Chad",
        code: "TD",
    },
    {
        name: "French Southern Territories",
        code: "TF",
    },
    {
        name: "Togo",
        code: "TG",
    },
    {
        name: "Thailand",
        code: "TH",
    },
    {
        name: "Tajikistan",
        code: "TJ",
    },
    {
        name: "Tokelau",
        code: "TK",
    },
    {
        name: "Timor-Leste",
        code: "TL",
    },
    {
        name: "Turkmenistan",
        code: "TM",
    },
    {
        name: "Tunisia",
        code: "TN",
    },
    {
        name: "Tonga",
        code: "TO",
    },
    {
        name: "Türkiye",
        code: "TR",
    },
    {
        name: "Trinidad and Tobago",
        code: "TT",
    },
    {
        name: "Tuvalu",
        code: "TV",
    },
    {
        name: "Taiwan",
        code: "TW",
    },
    {
        name: "Tanzania",
        code: "TZ",
    },
    {
        name: "Ukraine",
        code: "UA",
    },
    {
        name: "Uganda",
        code: "UG",
    },
    {
        name: "United States Minor Outlying Islands",
        code: "UM",
    },
    {
        name: "United States of America",
        code: "US",
    },
    {
        name: "Uruguay",
        code: "UY",
    },
    {
        name: "Uzbekistan",
        code: "UZ",
    },
    {
        name: "Holy See",
        code: "VA",
    },
    {
        name: "Saint Vincent and the Grenadines",
        code: "VC",
    },
    {
        name: "Venezuela",
        code: "VE",
    },
    {
        name: "Virgin Islands (British)",
        code: "VG",
    },
    {
        name: "Virgin Islands (U.S.)",
        code: "VI",
    },
    {
        name: "Vietnam",
        code: "VN",
    },
    {
        name: "Vanuatu",
        code: "VU",
    },
    {
        name: "Wallis and Futuna",
        code: "WF",
    },
    {
        name: "Samoa",
        code: "WS",
    },
    {
        name: "Yemen",
        code: "YE",
    },
    {
        name: "Mayotte",
        code: "YT",
    },
    {
        name: "South Africa",
        code: "ZA",
    },
    {
        name: "Zambia",
        code: "ZM",
    },
    {
        name: "Zimbabwe",
        code: "ZW",
    },
];

export const trackShipmentsMapOptions = {
    zoomControlOptions: {
        position: 7,
    },
    mapTypeControl: false,
    rotateControl: false,
    scaleControl: false,
    streetViewControl: false,
    minZoom: 3,
    styles: [
        {
            elementType: "labels.text.fill",
            stylers: [
                {
                    color: "#b1b9c4",
                },
            ],
        },
        {
            elementType: "labels.text.stroke",
            stylers: [
                {
                    visibility: "off",
                },
            ],
        },
        {
            featureType: "water",
            elementType: "geometry.fill",
            stylers: [
                {
                    color: "#f8f8fa",
                },
            ],
        },
        {
            featureType: "landscape.natural",
            elementType: "geometry",
            stylers: [
                {
                    color: "#e1e4eb",
                },
            ],
        },
        {
            featureType: "road",
            stylers: [
                {
                    visibility: "off",
                },
            ],
        },
        {
            featureType: "water",
            elementType: "labels.text.fill",
            stylers: [
                {
                    color: "#d8dbe3",
                },
            ],
        },
        {
            featureType: "transit",
            stylers: [
                {
                    visibility: "off",
                },
            ],
        },
        {
            featureType: "administrative.country",
            elementType: "geometry.stroke",
            stylers: [
                {
                    visibility: "off",
                },
            ],
        },
        {
            featureType: "road.arterial",
            elementType: "geometry.stroke",
            stylers: [
                {
                    visibility: "off",
                },
            ],
        },
        {
            featureType: "administrative",
            elementType: "geometry",
            stylers: [
                {
                    visibility: "off",
                },
            ],
        },
        {
            featureType: "administrative.province",
            stylers: [
                {
                    visibility: "off",
                },
            ],
        },
        {
            featureType: "administrative.land_parcel",
            stylers: [
                {
                    visibility: "off",
                },
            ],
        },
        {
            featureType: "poi",
            elementType: "geometry",
            stylers: [
                {
                    visibility: "off",
                },
            ],
        },
        {
            featureType: "poi",
            stylers: [
                {
                    visibility: "off",
                },
            ],
        },
    ],
};

export const AMAZON_WAREHOUSE_IDS = [
    { marketplace_id: "A2Q3Y263D00KWC", country_code: "BR" },
    { marketplace_id: "A2EUQ1WTGCTBG2", country_code: "CA" },
    { marketplace_id: "A1AM78C64UM0Y8", country_code: "MX" },
    { marketplace_id: "ATVPDKIKX0DER", country_code: "US" },
    { marketplace_id: "A2VIGQ35RCS4UG", country_code: "AE" },
    { marketplace_id: "A1PA6795UKMFR9", country_code: "DE" },
    { marketplace_id: "ARBP9OOSHTCHU", country_code: "EG" },
    { marketplace_id: "A1RKKUPIHCS9HS", country_code: "ES" },
    { marketplace_id: "A13V1IB3VIYZZH", country_code: "FR" },
    { marketplace_id: "AMEN7PMS3EDWL", country_code: "BE" },
    { marketplace_id: "A1F83G8C2ARO7P", country_code: "UK" },
    { marketplace_id: "A21TJRUUN4KGV", country_code: "IN" },
    { marketplace_id: "APJ6JRA9NG5V4", country_code: "IT" },
    { marketplace_id: "A1805IZSGTT6HS", country_code: "NL" },
    { marketplace_id: "A1C3SOZRARQ6R3", country_code: "PL" },
    { marketplace_id: "A17E79C6D8DWNP", country_code: "SA" },
    { marketplace_id: "A2NODRKZP88ZB9", country_code: "SE" },
    { marketplace_id: "A33AVAJ2PDY3EV", country_code: "TR" },
    { marketplace_id: "A19VAU5U5O7RUS", country_code: "SG" },
    { marketplace_id: "A39IBJ37TRP1C6", country_code: "AU" },
    { marketplace_id: "A1VC38T7YXB528", country_code: "JP" },
];

export const ALLOWED_ADDRESS_COUNTRIES = ["BR", "CA", "MX", "US", "AE", "DE", "EG", "ES", "FR", "UK", "GB", "BE", "IT", "IN", "NL", "PL", "SA", "SE", "TR", "JP", "CN", "SG", "AU"];

export const RATES_QUOTE_MARKETS = [
    {
        region: "North America",
        countries: [
            {
                country_code: "BR",
                country_name: "Brazil",
            },
            {
                country_code: "CA",
                country_name: "Canada",
            },
            {
                country_code: "MX",
                country_name: "Mexico",
            },
            {
                country_code: "US",
                country_name: "United States",
            },
        ],
    },
    {
        region: "Europe",
        countries: [
            {
                country_code: "BE",
                country_name: "Belgium",
            },
            {
                country_code: "CZ",
                country_name: "Czech",
            },
            {
                country_code: "EG",
                country_name: "Egypt",
            },
            {
                country_code: "FR",
                country_name: "France",
            },
            {
                country_code: "DE",
                country_name: "Germany",
            },
            {
                country_code: "IN",
                country_name: "India",
            },
            {
                country_code: "IT",
                country_name: "Italy",
            },
            {
                country_code: "NL",
                country_name: "Netherlands",
            },
            {
                country_code: "PL",
                country_name: "Poland",
            },
            {
                country_code: "SA",
                country_name: "Saudi Arabia",
            },
            {
                country_code: "ZA",
                country_name: "South Africa",
            },
            {
                country_code: "ES",
                country_name: "Spain",
            },
            {
                country_code: "SE",
                country_name: "Sweden",
            },
            {
                country_code: "TR",
                country_name: "Turkey",
            },
            {
                country_code: "AE",
                country_name: "United Arab Emirates",
            },
            {
                country_code: "UK",
                country_name: "United Kingdom",
            },
        ],
    },
];
